import React from 'react';
import { Navigate } from 'react-router-dom';
import { supabase } from '../utils/supabase';

const PrivateRoute = ({ children }) => {
  return (
    <>
      {supabase.auth.session()?.access_token ? (
        children
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default PrivateRoute;
