import React from 'react';
import styled from 'styled-components';
import Button from './style/Button';

const ModalContainer = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

const ModalContent = styled.div`
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  border-radius: 5px;
  width: 80%; /* Could be more or less, depending on screen size */
  max-width: 500px;
`;

const X = styled.span`
  font-size: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const Modal = ({
  title,
  children,
  onOk,
  visible = false,
  loading,
  onCancel,
}) => {
  if (!visible) return null;

  return (
    <ModalContainer>
      <ModalContent>
        <div style={{ display: 'flex', marginBottom: '1rem' }}>
          <h4>{title}</h4>
          <X style={{ margin: '0 0 0 auto' }} onClick={() => onCancel()}>
            &times;
          </X>
        </div>
        <div>{children}</div>
        <div style={{ display: 'flex' }}>
          <Button
            type="secondary"
            disabled={loading}
            style={{ margin: '0 0 0 auto' }}
            onClick={() => onOk()}
          >
            Ok
          </Button>
        </div>
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
