import styled from 'styled-components';

export const Error = styled.p`
  padding: 0.5rem 0.25rem;
  border: 1px solid red;
  border-radius: 5px;
  background-color: #ffd3cf;
  font-size: 13px;
  font-weight: 700;
`;
