import React from 'react';
import Layout from './style/layout';
import styled from 'styled-components';
import { Link as L } from 'react-router-dom';
import mapFields from '../map-fields.png';
import buildRequest from '../build-request.png';
import selectSheet from '../select-sheet.png';
import authorizeSmartsheet from '../authorize-smartsheet.png';
import smartsheetAuth from '../smartsheet-auth.png';
import schedule from '../schedule.png';

const Container = styled.div`
  line-height: 1.5;
  > * {
    margin-top: 1rem;
  }
`;

// const Highlight = styled.span`
//   background-color: var(--grey);
//   padding: 0.1rem 0.2rem;
//   border-radius: 5px;
// `;

export const Note = styled.p`
  font-size: 13px;
  color: #bbbdbb;
`;

const Link = styled(L)`
  // &:hover {
  color: var(--main-color);
  // }
`;

const Docs = () => {
  return (
    <Layout>
      <Container
        style={{
          margin: 'auto',
          padding: '1rem',
          maxWidth: '750px',
          width: '100%',
          minHeight: '100vh',
        }}
      >
        <h2>Docs</h2>
        {/* <h3>Step 1: Create a Supabase project</h3>
        <p>If you already have your desired project you can skip this step.</p>
        <p>
          From the <a href="https://app.supabase.com/">Supabase dashboard</a>,
          click <Highlight>New project</Highlight> and select an organization.
        </p>
        <p>Fill in the required fields with your desired inputs and create.</p>
        <p>
          Supabase will take a couple of minutes to configure the
          infrastructure.
        </p> */}

        <h3>Step 1: Authorize Access</h3>
        <p>
          From your{' '}
          <Link href={`${process.env.REACT_APP_BASE_URL}/home`}>
            home dashboard
          </Link>{' '}
          click authorize where you will redirected to Smartsheet.
        </p>
        <img
          style={{ width: '60%' }}
          src={authorizeSmartsheet}
          alt="Link to Smartsheet auth"
        />

        <p>
          These will give access to read, write, and add columns to your sheets.
        </p>
        <img
          style={{ width: '60%' }}
          src={smartsheetAuth}
          alt="Authorize access to your smartsheet account"
        />

        <h3>Step 2: Create an API Request</h3>
        <p>
          Build your request to any API endpoint you wish to pull data from.
        </p>
        <img
          style={{ width: '100%' }}
          src={buildRequest}
          alt="Build your request"
        />
        <h3>Step 3: Select Sheet</h3>
        <p>Select which Smartsheet sheet you want to insert into.</p>
        <p>Run your request.</p>
        <img
          style={{ width: '100%' }}
          src={selectSheet}
          alt="Select the Smartsheet sheet name"
        />
        <h3>Step 4: Map Fields and Insert</h3>
        <p>
          Map the data returned from your request to the correct fields of the
          sheet it is being inserted into.
        </p>
        <img
          style={{ width: '100%' }}
          src={mapFields}
          alt="Map fields to smartsheet column"
        />
        <Note>
          *Make sure the field types for each column are compatible or you'll
          get errors.
        </Note>
        <p>Insert your data.</p>
        <h3>Step 5: Schedule Request (Optional)</h3>
        <p>
          Schedule your custom request to run automatically, even when you're
          not logged in.
        </p>
        <p>Select your frequency and the day(s) for your request to run.</p>
        <img
          style={{ width: '100%' }}
          src={schedule}
          alt="Schedule request example"
        />
        {/* <Note>
          *Make sure the field types for each column are compatible or you'll
          get errors.
        </Note> */}
        <p>Save your request!</p>
      </Container>
    </Layout>
  );
};

export default Docs;
