import { useEffect, useState } from 'react';
import { supabase } from '../utils/supabase';
import axios from 'axios';

export const useQuery = (path) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getData = async () => {
    setLoading(true);

    const { data: axiosData } = await axios.post(
      `${path}`,
      {},
      {
        headers: {
          Accept: 'application/json',
          [`x-auth-token`]: supabase.auth.session()?.access_token,
        },
      }
    );

    setData(axiosData);
    setLoading(false);
    return;
  };

  useEffect(() => {
    getData();
  }, [path]);

  return { data, loading, getData };
};
