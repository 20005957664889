import React from 'react';
import styled from 'styled-components';
import Button from '../style/Button';

export const MultiInput = styled.input`
  // width: 43.25%;
  margin-right: 0.5%;
  flex-grow: 10;
`;

const MultiInputForm = ({ items, setItems, type }) => {
  function handleChange(value, id, key) {
    setItems(
      items.map((p, i) => {
        if (i === id) {
          return {
            ...p,
            [`${key}`]: value,
          };
        }

        return p;
      })
    );
  }

  return (
    <div>
      {items?.length > 0 && (
        <div>
          {items.map((p, i) => (
            <div
              style={{
                display: 'flex',
                marginBottom: '.5rem',
              }}
              key={i}
            >
              <MultiInput
                placeholder={type}
                value={p[`${type}`]}
                onChange={(e) => handleChange(e.target.value, i, type)}
              />
              <MultiInput
                placeholder="value"
                value={p.value}
                onChange={(e) => handleChange(e.target.value, i, 'value')}
              />
              <Button
                type="secondary"
                style={{
                  padding: '0.3rem 0.75rem',
                  flexGrow: '1',
                }}
                onClick={() =>
                  setItems(items.filter((p, index) => i !== index))
                }
              >
                -
              </Button>
            </div>
          ))}
        </div>
      )}
      <Button
        type="secondary"
        onClick={() => setItems([...items, { [`${type}`]: '', value: '' }])}
      >
        Add +
      </Button>
    </div>
  );
};

export default MultiInputForm;
