import React from 'react';
import { CustomRequestContainer } from '../custom-request';
import { SubHeader } from '../supa-form';
import On from './on';
import axios from 'axios';
import { supabase } from '../../utils/supabase';
import styled from 'styled-components';

const ClickableText = styled.p`
  color: #8a24ff;
  font-size: 14px;
  margin-top: 0.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const Schedule = ({ config, setConfig, requestId }) => {
  return (
    <CustomRequestContainer style={{ marginTop: '1rem' }}>
      <h2>Schedule Request</h2>
      <SubHeader>Scheduled requests run automatically.</SubHeader>
      <div
        style={{ marginTop: '.5rem', display: 'flex', alignItems: 'center' }}
      >
        <input
          type="checkbox"
          checked={config.scheduleEnabled}
          onChange={(e) =>
            setConfig({ ...config, scheduleEnabled: e.target.checked })
          }
        />{' '}
        <span style={{ marginLeft: '.25rem' }}>Schedule this request</span>
      </div>
      {config.scheduleEnabled && (
        <div>
          <h3 style={{ marginTop: '.5rem' }}>Frequency</h3>
          <select
            style={{ marginTop: '.5rem' }}
            value={config.scheduleFrequency}
            onChange={(e) =>
              setConfig({
                ...config,
                scheduleFrequency: e.target.value,
                scheduleDays: [],
              })
            }
          >
            <option value="" disabled>
              - Select Frequency -
            </option>
            <option value="daily">Daily</option>
            <option value="weekly">Weekly</option>
          </select>
          <On config={config} setConfig={setConfig} />
          {/* <ClickableText
            onClick={async () => {
              const { data: scheduledRequestsResults } = await axios.get(
                `/api/schedules/${requestId}`,
                {
                  headers: {
                    [`x-auth-token`]: supabase.auth.session()?.access_token,
                  },
                }
              );
              console.log(scheduledRequestsResults);
            }}
          >
            View scheduled requests responses
          </ClickableText> */}
        </div>
      )}
    </CustomRequestContainer>
  );
};

export default Schedule;
