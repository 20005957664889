import React from 'react';
import Layout from './style/layout';
import styled from 'styled-components';

const H3 = styled.h3`
  margin-bottom: 1rem;
`;
const Paragraph = styled.p`
  margin-bottom: 1rem;
`;

const HowToImportData = () => {
  return (
    <Layout>
      <div
        style={{
          margin: 'auto',
          padding: '1rem',
          maxWidth: '1000px',
          width: '100%',
          lineHeight: '1.6',
        }}
      >
        <h2 style={{ marginBottom: '1rem' }}>
          How To Import Data Into Your Smartsheet Account
        </h2>
        <ul style={{ listStylePosition: 'inside', marginBottom: '1rem' }}>
          <li>
            <a href="#import-from-file">Import From A File</a>
          </li>
          <li>
            <a href="#import-from-an-api">Import From An API</a>
          </li>
        </ul>
        <div id="import-from-file">
          <H3>How To Import Data From A File</H3>
          <Paragraph>
            Importing data into Smartsheet can be a useful way to quickly
            populate a sheet with large amounts of information. This can save
            time and effort, especially if the data is coming from another
            program or database.
          </Paragraph>

          <Paragraph>
            To import data into Smartsheet, first make sure that the data is in
            a format that Smartsheet can recognize. This means that the data
            should be in a spreadsheet or CSV (comma-separated values) file.
            Smartsheet can also import data from certain other programs, such as
            Excel and Google Sheets.
          </Paragraph>

          <Paragraph>
            Once the data is in a compatible format, go to the Smartsheet page
            where you want to import the data. Click on the "Import" button,
            which is typically located in the top right corner of the page. This
            will open a dialog box where you can select the file that you want
            to import.
          </Paragraph>

          <Paragraph>
            After selecting the file, you will be given the option to specify
            how the data should be imported. This includes options such as which
            columns in the file should be imported, and whether the data should
            be added to an existing sheet or to a new sheet.
          </Paragraph>

          <Paragraph>
            Once you have specified the import options, click on the "Import"
            button to start the import process. Smartsheet will then import the
            data and display it in the sheet.
          </Paragraph>

          <Paragraph>
            Importing data into Smartsheet can be a useful way to quickly and
            easily add large amounts of information to a sheet. This can save
            time and effort, and can help to ensure that the data is organized
            and easy to work with.
          </Paragraph>
        </div>
        <div id="import-from-an-api">
          <H3>How To Import Data From Any API</H3>
          <Paragraph>
            With API Retriever, importing data from any common software has
            never been easier. To start you will need to sign up for a API
            Retriever account.
          </Paragraph>

          <Paragraph>
            Once you have an account you will need to build your request in
            order to get your data. You will need to find the URL or endpoint of
            where to pull your data from. Often times you will also need some
            sort of authorization header which can be added when building your
            request.
          </Paragraph>

          <Paragraph>
            When you have the data you want returning from your call you can
            choose which columns you would like each field to be inserted into.
            Click insert, reload your sheet, and see your data!
          </Paragraph>
        </div>
      </div>
    </Layout>
  );
};

export default HowToImportData;
