import React, { useState } from 'react';
import styled from 'styled-components';
import { supabase } from '../../utils/supabase';
import { useNavigate } from 'react-router-dom';
import Layout from '../style/layout';
import { Error } from '../style/error';
import Button from '../style/Button';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 3rem 1rem;
  text-align: center;
  margin: 5rem auto auto auto;

  > * {
    margin-top: 1rem !important;
  }
`;

const Login = () => {
  const [creds, setCreds] = useState({
    email: '',
    password: '',
  });
  const [error, setError] = useState(``);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) =>
    setCreds({ ...creds, [e.target.name]: e.target.value });

  const handleSubmit = async (type) => {
    setLoading(true);
    let loginError;
    if (type === `email`) {
      const { error: newLoginError } = await supabase.auth.signIn({
        email: creds.email,
        password: creds.password,
      });

      loginError = newLoginError;
    } else {
      const { error: newLoginError } = await supabase.auth.signIn(
        {
          provider: 'google',
        },
        { redirectTo: `${process.env.REACT_APP_BASE_URL}/callback` }
      );

      loginError = newLoginError;
    }
    setLoading(false);

    if (loginError?.message) {
      setError(loginError?.message);
      return;
    }

    setCreds({
      email: '',
      password: '',
    });
    // navigate to the app
    navigate('/home');
  };

  return (
    <Layout>
      <div style={{ display: 'flex', minHeight: '100vh' }}>
        <Form>
          <h2>Login</h2>
          {error && <Error>{error}</Error>}
          <input
            value={creds.email}
            placeholder="Email"
            name="email"
            onChange={(e) => handleChange(e)}
          />
          <input
            value={creds.password}
            type="password"
            placeholder="Password"
            name="password"
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(`email`);
              }
            }}
          />
          <Button onClick={() => handleSubmit(`email`)} disabled={loading}>
            Login
          </Button>
          {/* <button onClick={() => handleSubmit(`google`)} disabled={loading}>
            Login With Google
          </button> */}
        </Form>
      </div>
    </Layout>
  );
};

export default Login;
