import { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Error } from './style/error';
import { SubHeader } from './supa-form';
import { supabase } from '../utils/supabase';
import { Msg } from './style';
import { getCustomRequestConfig } from '../utils/custom-request';
import Button from './style/Button';

import TableFieldsSelect from './table-fields-select';

const CompareFieldsContainer = styled.div`
  padding: 0.5rem;
  border: 1px solid #ededed;
  border-radius: 5px;
  margin-top: 1rem;
`;

const TableContainer = styled.div`
  max-width: 100%;
  overflow-x: scroll;
  margin-bottom: 0.5rem;
  max-height: 900px;
  overflow-y: auto;
`;

export function messageTimeout(set, msg) {
  set(msg);

  setTimeout(() => set(``), 10000);
}

async function runQuery({
  selectedMappings,
  table,
  setError,
  setLoading,
  setMsg,
  customRequestProps,
  fields,
  clearRows,
}) {
  setLoading(true);
  const requestConfig = getCustomRequestConfig(customRequestProps);
  const { data } = await axios.post(
    `/api/run/insert`,
    {
      selectedMappings,
      sheetId: table,
      requestConfig,
      fields,
      clearRows,
    },
    {
      headers: {
        Accept: 'application/json',
        [`x-auth-token`]: supabase.auth.session()?.access_token,
      },
    }
  );
  setLoading(false);
  if (data?.success === false) {
    setError(data?.data);
    return;
  }

  messageTimeout(setMsg, `Successfully inserted your data.`);
  setError(undefined);
  return data;
}

const CompareFields = ({
  runData,
  table,
  supabaseCreds,
  customRequestProps,
  selectedMappings,
  setSelectedMappings,
  setClearRows,
  clearRows,
}) => {
  const [error, setError] = useState(undefined);
  const [msg, setMsg] = useState(``);
  const [loading, setLoading] = useState(false);
  // const [selectedMappings, setSelectedMappings] = useState(
  //   runData?.mappedFields
  // );
  const mappedFields = runData?.mappedFields;
  const externalData = runData?.externalData;
  // const externalData = runData?.externalData?.slice(0, 10);
  const fieldsArray = runData?.fields ?? [];

  return (
    <CompareFieldsContainer>
      <h2>Map Fields</h2>
      <SubHeader>
        Map the fields returned from your request to the fields in your sheet
      </SubHeader>

      <div style={{ margin: '.5rem 0', display: 'flex', alignItems: 'center' }}>
        <input
          type="checkbox"
          checked={clearRows}
          onChange={(e) => setClearRows(e.target.checked)}
        />
        <span
          style={{ marginLeft: '.25rem', fontSize: '12px' }}
          onClick={() => setClearRows(!clearRows)}
        >
          Clear all rows in sheet before inserting new data (on each request)
        </span>
      </div>

      {error && <Error style={{ marginBottom: '.5rem' }}>{error}</Error>}
      {msg && <Msg style={{ marginBottom: '.5rem' }}>{msg}</Msg>}
      <Button
        type="secondary"
        style={{ marginBottom: '.5rem' }}
        disabled={
          !externalData || !(selectedMappings || mappedFields) || loading
        }
        onClick={async () => {
          await runQuery({
            selectedMappings: selectedMappings || mappedFields,
            table,
            setError,
            supabaseCreds,
            setLoading,
            setMsg,
            customRequestProps,
            fields: runData?.fields,
            clearRows,
          });
        }}
      >
        Insert Data
      </Button>

      {mappedFields && (
        <TableContainer>
          <table>
            <thead>
              <tr>
                <th>Response Field Name</th>
                <th>Sheet Column Name</th>
                <th>Data Response Example</th>
              </tr>
            </thead>
            <tbody>
              {mappedFields?.map((mf, i) => {
                const fieldValue = externalData[`${mf?.fieldName}`];

                return (
                  <tr key={i}>
                    <td>{mf.fieldName}</td>
                    <td>
                      <TableFieldsSelect
                        fields={fieldsArray}
                        selectedMappings={selectedMappings || mappedFields}
                        setSelectedMappings={setSelectedMappings}
                        fieldName={mf?.fieldName}
                      />
                    </td>
                    <td>
                      <div
                        style={{
                          maxHeight: '200px',
                          overflowY: 'auto',
                          textAlign: 'center',
                        }}
                      >
                        {typeof fieldValue !== 'string' ||
                        typeof fieldValue !== 'number'
                          ? JSON.stringify(fieldValue)
                          : fieldValue}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableContainer>
      )}
      {/* {mappedFields && (
        <TableContainer>
          <table>
            <thead>
              <tr>
                {mappedFields?.map((mf) => {
                  return (
                    <th key={mf?.fieldName}>
                      <p style={{ marginBottom: '.5rem' }}>{mf.fieldName}</p>
                      <TableFieldsSelect
                        fields={fieldsArray}
                        selectedMappings={selectedMappings || mappedFields}
                        setSelectedMappings={setSelectedMappings}
                        fieldName={mf?.fieldName}
                      />
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              <tr>
                {mappedFields?.map((mf, i) => {
                  const fieldValue = externalData[`${mf?.fieldName}`];

                  return (
                    <td key={i}>
                      <div
                        style={{
                          maxHeight: '200px',
                          overflowY: 'auto',
                          textAlign: 'center',
                        }}
                      >
                        {typeof fieldValue !== 'string' ||
                        typeof fieldValue !== 'number'
                          ? JSON.stringify(fieldValue)
                          : fieldValue}
                      </div>
                    </td>
                  );
                })}
              </tr>
            </tbody>
          </table>
        </TableContainer>
      )} */}
    </CompareFieldsContainer>
  );
};

export default CompareFields;
