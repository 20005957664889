import React from 'react';
import styled from 'styled-components';

const PrivacyContainer = styled.div`
  & > p {
    margin: 0 0 1rem 0;
  }

  & > h4 {
    margin: 0.25rem 0;
  }
`;

const PrivacyPolicy = () => {
  return (
    <PrivacyContainer>
      <h4>Privacy policy</h4>
      <p>
        The privacy of your data—and it is your data, not ours!—is a big deal to
        us. In this policy, we lay out: what data we collect and why; how your
        data is handled; and your rights with respect to your data. We promise
        we never sell your data: never have, never will. This policy applies to
        apiretriever.com.
      </p>

      <h4>What we collect and why</h4>
      <p>
        Our guiding principle is to collect only what we need. Here’s what that
        means in practice:
      </p>

      <h4>Identity & access</h4>
      <p>
        When you sign up for apiretriever, we ask for identifying information
        such as your name, email address, and maybe a company name. That’s so
        you can personalize your new account, and we can send you product
        updates and other essential information. We may also send you optional
        surveys from time to time to help us understand how you use our products
        and to make improvements. With your consent, we will send you other
        updates. We’ll never sell your personal information to third parties,
        and we won’t use your name or company in marketing statements without
        your permission either.
      </p>

      <h4>Billing information</h4>
      <p>
        If you sign up for a paid apiretriever account, you will be asked to
        provide your payment information and billing address. Credit card
        information is submitted directly to our payment processor and doesn’t
        hit apiretrievers servers. We store a record of the payment transaction,
        including the last 4 digits of the credit card number, for purposes of
        account history, invoicing, and billing support. We store your billing
        address so we can charge you for service, calculate any sales tax due,
        send you invoices, and detect fraudulent credit card transactions. We
        occasionally use aggregate billing information to guide our marketing
        efforts.
      </p>

      <h4>Product interactions</h4>
      <p>
        We store on our servers the content that you upload or receive or
        maintain in your apiretriever accounts. This is so you can use our
        products as intended, for example, when you save your Smartsheet
        credentials to make requests we save those for any future requests. This
        information is not shared in any way and is completely removed when your
        account is deleted.{' '}
      </p>

      <h4>Geolocation data</h4>
      <p>
        For most of our products, we log the full IP address used to sign up a
        product account and retain that for use in mitigating future spammy
        signups. We also log all account access by full IP address for security
        and fraud prevention purposes, and we keep this login data for as long
        as your product account is active.
      </p>

      <h4>Website interactions</h4>
      <p>
        We collect information about your browsing activity for analytics and
        statistical purposes such as conversion rate testing and experimenting
        with new product designs. This includes, for example, your browser and
        operating system versions, your IP address, which web pages you visited
        and how long they took to load, and which website referred you to us. If
        you have an account and are signed in, these web analytics data are tied
        to your IP address and user account until your account is no longer
        active. The web analytics we use are described further in the
        Advertising and Cookies section.
      </p>

      <h4>Anti-bot assessments</h4>
      <p>
        We may use third-party CAPTCHA services across our applications to
        mitigate brute force logins and in HEY as a means of spam protection. We
        have a legitimate interest in protecting our apps and the broader
        Internet community from credential stuffing attacks and spam. When you
        log into your apiretriever account and when you fill in certain forms in
        HEY, the CAPTCHA service evaluates various information (e.g., IP
        address, how long the visitor has been on the app, mouse movements) to
        try to detect if the activity is from an automated program instead of a
        human. We retain these data via our subprocessor indefinitely for use in
        spam mitigation.
      </p>

      <h4>Advertising and Cookies</h4>
      <p>
        apiretriever may run contextual ads on various third-party platforms
        such as Google, Reddit, and LinkedIn. Users who click on one of our ads
        will be sent to the Basecamp marketing site. Where permissible under
        law, we may load an ad-company script on their browsers that sets a
        third-party cookie and sends information to the ad network to enable
        evaluation of the effectiveness of our ads, e.g., which ad they clicked
        and which keyword triggered the ad, and whether they performed certain
        actions such as clicking a button or submitting a form. We also use
        persistent first-party cookies and some third-party cookies to store
        certain preferences, make it easier for you to use our applications, and
        perform A/B testing as well as support some analytics. A cookie is a
        piece of text stored by your browser. It may help remember login
        information and site preferences. It might also collect information such
        as your browser type, operating system, web pages visited, duration of
        visit, content viewed, and other click-stream data. You can adjust
        cookie retention settings and accept or block individual cookies in your
        browser settings, although our apps won’t work and other aspects of our
        service may not function properly if you turn cookies off.
      </p>

      <h4>Voluntary correspondence</h4>
      <p>
        When you email apiretriever with a question or to ask for help, we keep
        that correspondence, including your email address, so that we have a
        history of past correspondence to reference if you reach out in the
        future. We also store information you may volunteer, for example,
        written responses to surveys. If you agree to a customer interview, we
        may ask for your permission to record the conversation for future
        reference or use. We will only do so with your express consent.
      </p>

      <h4>When we access or share your information</h4>
      <p>
        To provide products or services you’ve requested. We use Supabase to
        store and access your information to help run our applications and
        provide the Services to you. We may share your information at your
        direction if you integrate a third-party service into your use of our
        products. For example, we may allow you, at your option, to connect your
        Google account so you can easily pull data from their api. To help you
        troubleshoot or squash a software bug, with your permission. If at any
        point we need to access your content to help you with a support case, we
        will ask for your consent before proceeding. To investigate, prevent, or
        take action regarding restricted uses. Accessing a customer’s account
        when investigating potential abuse is a measure of last resort. We want
        to protect the privacy and safety of both our customers and the people
        reporting issues to us, and we do our best to balance those
        responsibilities throughout the process. If we discover you are using
        our products for a restricted purpose, we will take action as necessary,
        including notifying appropriate authorities where warranted. When
        required under applicable law.
      </p>

      <p>
        all data infrastructure is located in the U.S. Requests for user data.
        Our policy is to not respond to government requests for user data unless
        we are compelled by legal process or in limited circumstances in the
        event of an emergency request. However, if U.S. law enforcement
        authorities have the necessary warrant, criminal subpoena, or court
        order requiring us to share data, we must comply. Likewise, we will only
        respond to requests from government authorities outside the U.S. if
        compelled by the U.S. government through procedures outlined in a mutual
        legal assistance treaty or agreement. It is apiretriever policy to
        notify affected users before we share data unless we are legally
        prohibited from doing so, and except in some emergency cases.
        Preservation requests. Similarly, apiretriever policy is to comply with
        requests to preserve data only if compelled by the U.S. Federal Stored
        Communications Act, 18 U.S.C. Section 2703(f), or by a properly served
        U.S. subpoena for civil matters. We do not share preserved data unless
        required by law or compelled by a court order that we choose not to
        appeal. Furthermore, unless we receive a proper warrant, court order, or
        subpoena before the required preservation period expires, we will
        destroy any preserved copies of customer data at the end of the
        preservation period. If we are audited by a tax authority, we may be
        required to share billing-related information. If that happens, we will
        share only the minimum needed, such as billing addresses and tax
        exemption information.
      </p>

      <p>
        Finally, if apiretriever is acquired by or merges with another company —
        we don’t plan on that, but if it happens — we’ll notify you well before
        any of your personal information is transferred or becomes subject to a
        different privacy policy. Your rights with respect to your information
        At apiretriever, we strive to apply the same data rights to all
        customers, regardless of their location. Some of these rights include:
        Right to Know. You have the right to know what personal information is
        collected, used, shared or sold. We outline both the categories and
        specific bits of data we collect, as well as how they are used, in this
        privacy policy.
        <br />
        Right of Access. This includes your right to access the personal
        information we gather about you, and your right to obtain information
        about the sharing, storage, security and processing of that information.
        <br />
        Right to Correction. You have the right to request correction of your
        personal information.
        <br />
        Right to Erasure / “To Be Forgotten”. This is your right to request,
        subject to certain limitations under applicable law, that your personal
        information be erased from our possession and, by extension, from all of
        our service providers. Fulfillment of some data deletion requests may
        prevent you from using 37signals services because our applications may
        then no longer work. In such cases, a data deletion request may result
        in closing your account.
        <br />
        Right to Complain. You have the right to make a complaint regarding our
        handling of your personal information with the appropriate supervisory
        authority.
        <br />
        Right to Restrict Processing. This is your right to request restriction
        of how and why your personal information is used or processed, including
        opting out of sale of personal information. (Again: we never have and
        never will sell your personal data.)
        <br />
        Right to Object. You have the right, in certain situations, to object to
        how or why your personal information is processed.
        <br />
        Right to Portability. You have the right to receive the personal
        information we have about you and the right to transmit it to another
        party. If you want to export data from your accounts, you can do so
        directly by following these instructions for apiretriever.
        <br />
        Right to not Be Subject to Automated Decision-Making. You have the right
        to object to and prevent any decision that could have a legal or
        similarly significant effect on you from being made solely based on
        automated processes. This right is limited if the decision is necessary
        for performance of any contract between you and us, is allowed by
        applicable law, or is based on your explicit consent.
        <br />
        Right to Non-Discrimination. We do not and will not charge you a
        different amount to use our products, offer you different discounts, or
        give you a lower level of customer service because you have exercised
        your data privacy rights. However, the exercise of certain rights may,
        by virtue of your exercising those rights, prevent you from using our
        Services.
        <br />
        Many of these rights can be exercised by signing in and updating your
        account information.
      </p>

      <p>
        If you have questions about exercising these rights or need assistance,
        please contact us at apiretriever@gmail.com. If an authorized agent is
        corresponding on your behalf, we will need written consent with a
        signature from the account holder before proceeding.
      </p>

      <p>
        If you are in the EU or UK, you can contact your data protection
        authority to file a complaint or learn more about local privacy laws.
      </p>

      <h4>How we secure your data</h4>
      <p>
        All data is encrypted via SSL/TLS when transmitted from our servers to
        your browser. The database backups are also encrypted. In addition, we
        go to great lengths to secure your data at rest.
      </p>

      <h4>What happens when you delete content in your product accounts</h4>
      <p>
        If you choose to cancel your account, your content will become
        immediately inaccessible and should be purged from our systems in full
        within 60 days. This applies both for cases when an account owner
        directly cancels and for auto-cancelled accounts.
      </p>

      <h4>Location of site and data</h4>
      <p>
        Our products and other web properties are operated in the United States.
        If you are located in the European Union, UK, or elsewhere outside of
        the United States, please be aware that any information you provide to
        us will be transferred to and stored in the United States. By using our
        websites or Services and/or providing us with your personal information,
        you consent to this transfer.
      </p>

      <h4>Changes & questions</h4>
      <p>
        We may update this policy as needed to comply with relevant regulations
        and reflect any new practices. Whenever we make a significant change to
        our policies, we will refresh the date at the top of this page and take
        any other appropriate steps to notify users. Have any questions,
        comments, or concerns about this privacy policy, your data, or your
        rights with respect to your information? Please get in touch by emailing
        us at apiretriever@gmail.com and we’ll be happy to try to answer them!
      </p>

      <a href="https://github.com/basecamp/policies/blob/master/privacy/index.md">
        Adapted from the Basecamp open-source policies / CC BY 4.0
      </a>
    </PrivacyContainer>
  );
};

export default PrivacyPolicy;
