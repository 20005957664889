import React from 'react';
import Layout from './style/layout';
import styled from 'styled-components';
import { useNavigate, Link } from 'react-router-dom';
import QuickDemo from '../quick-demo.mov';
import Button from './style/Button';

import mapFields from '../map-fields.png';
import buildRequest from '../build-request.png';
import schedule from '../schedule.png';
import selectSheet from '../select-sheet.png';
import authorizeSmartsheet from '../authorize-smartsheet.png';
import smartsheetAuth from '../smartsheet-auth.png';

const Banner = styled.div`
  width: 100%;
  padding: 5% 16px;
  text-align: center;
  max-width: 1200px;
  margin: auto;
`;

export const Title = styled.p`
  font-size: 300% !important;
  margin-bottom: 16px !important;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 300% !important;
  }
`;

const Btn = styled(Button)`
  font-size: 150%;
  height: auto !important;
`;

const Img = styled(Image)`
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  border-radius: 10px;

  @media (max-width: 990px) {
    margin-top: 10%;
  }
`;

const FeatureItem = styled.p`
  font-size: 18px;
  line-height: 2;
`;

const StepContainerOdd = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

const StepContainerEven = styled.div`
  display: flex;
  width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Step = styled.div`
  width: 50%;
  display: flex;
  text-align: center;
  margin: 2rem 0;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StepText = styled.h2`
  margin: auto;
  max-width: 350px;
`;

const StepImage = styled.img`
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 2px;
`;

const Home = () => {
  const navigate = useNavigate();
  return (
    <Layout style={{ display: 'flex' }}>
      <div
        style={{
          margin: 'auto',
          padding: '1rem',
          maxWidth: '1000px',
          width: '100%',
        }}
      >
        <Banner>
          <div span={24} lg={14} style={{ margin: 'auto', maxWidth: '600px' }}>
            <Title>Import Data From Any API Into Smartsheet</Title>
            <div
              style={{
                maxWidth: '450px',
                margin: 'auto',
              }}
            >
              <p
                style={{
                  fontSize: '18px',
                  color: '#8c8c8c',
                }}
              >
                Request data from any API, map that data to your smartsheet
                fields, and import or schedule it.
              </p>
            </div>
            <div>
              <Btn
                type="primary"
                style={{
                  padding: '12px 16px',
                  fontWeight: 'bold',
                  fontSize: '24px',
                  marginTop: '16px',
                }}
                onClick={() => navigate('/sign-up')}
              >
                Sign-Up For Free!
              </Btn>
              <p
                style={{
                  fontSize: '12px',
                  color: '#8c8c8c',
                  display: 'block',
                  marginTop: '10px',
                }}
              >
                *While in beta
              </p>
            </div>
          </div>
        </Banner>
        <div
          style={{
            maxWidth: '750px',
            margin: '2.5% auto 5% auto',
            textAlign: 'center',
            padding: '5%',
          }}
        >
          <video
            controls
            style={{
              width: '100%',
              borderRadius: '5px',
              boxShadow: 'rgba(0, 0, 0, 0.09) 0px 3px 12px',
            }}
          >
            <source src={QuickDemo} type="video/mp4" />
          </video>
        </div>
        {/* <div
          style={{
            maxWidth: '600px',
            margin: '5% auto',
            textAlign: 'center',
            padding: '5%',
            backgroundColor: '#fafafa',
            borderRadius: '5px',
          }}
        >
          <h2>How To</h2>
          <div>
            <FeatureItem>- Authorize access to your Supabase Org</FeatureItem>
          </div>
          <div>
            <FeatureItem>- Build your rquest to any endpoint</FeatureItem>
          </div>
          <div>
            <FeatureItem>- Select your Supabase table</FeatureItem>
          </div>
          <div>
            <FeatureItem>
              - Run your request and map the data to your Supabase fields{' '}
            </FeatureItem>
          </div>
          <div>
            <FeatureItem>- Insert into your table</FeatureItem>
          </div>
          <Btn
            type="primary"
            style={{
              padding: '12px 16px',
              fontWeight: 'bold',
              fontSize: '24px',
              marginTop: '16px',
            }}
            onClick={() => navigate('/sign-up')}
          >
            Sign Up Now
          </Btn>
        </div> */}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ margin: '3rem 0 5rem 0', textAlign: 'center' }}>
            <h1>
              <Link style={{ color: 'var(--main-color)' }} to="/sign-up">
                Sign Up
              </Link>{' '}
              for a Data Retriever account
            </h1>
          </div>
          <StepContainerOdd>
            <Step>
              <div>
                <StepImage
                  src={authorizeSmartsheet}
                  alt="authorize-smartsheet"
                />
              </div>
            </Step>
            <Step>
              <StepText>Authorize access to Smartsheet</StepText>
            </Step>
          </StepContainerOdd>
          <StepContainerEven>
            <Step>
              <StepText>Build a request, Save it for easy reuse</StepText>
            </Step>
            <Step>
              <div>
                <StepImage src={buildRequest} alt="build-request" />
              </div>
            </Step>
          </StepContainerEven>
          <StepContainerOdd>
            <Step>
              <div>
                <StepImage src={mapFields} alt="map-fields" />
              </div>
            </Step>
            <Step>
              <StepText>Map your fields & insert your data</StepText>
            </Step>
          </StepContainerOdd>
          <StepContainerEven>
            <Step>
              <StepText>Schedule your requests to run automatically</StepText>
            </Step>
            <Step>
              <div>
                <StepImage src={schedule} alt="schedule-request" />
              </div>
            </Step>
          </StepContainerEven>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
