import React, { useState } from 'react';
import { useQuery } from '../hooks/useQuery';
import axios from 'axios';
import isUrl from 'is-url';
import { getCustomRequestConfig } from '../utils/custom-request';
import styled from 'styled-components';
import CustomRequest, { CustomRequestContainer } from './custom-request';
import TableSelect from './table-select';
import CompareFields from './compare-fields';
import { Error } from './style/error';
import { supabase } from '../utils/supabase';
import Button from './style/Button';
import { Msg } from './style';
import { Link } from 'react-router-dom';
import Schedule from './scheduling/schedule';

export function messageTimeout(set, msg) {
  set(msg);

  setTimeout(() => set(``), 5000);
}

export const SubHeader = styled.p`
  font-size: 15px;
  margin: 0.15rem 0 1rem 0;
  color: #bbbdbb;
`;

async function runQuery({ type, table, customRequestProps, setRunData }) {
  setRunData({});
  if (!isUrl(customRequestProps?.url)) {
    return customRequestProps?.setUrlError(`Please use a valid URL.`);
  }

  const requestConfig = getCustomRequestConfig(customRequestProps);

  const { data } = await axios.post(
    `/api/run`,
    {
      type,
      sheetId: table,
      requestConfig,
    },
    {
      headers: {
        Accept: 'application/json',
        [`x-auth-token`]: supabase.auth.session()?.access_token,
      },
    }
  );

  customRequestProps?.setUrlError(undefined);
  return data;
}

async function saveRequest({
  table,
  url,
  method,
  params,
  headers,
  basicAuth,
  body,
  bodyType,
  selectedMappings,
  name,
  id,
  scheduleConfig,
  clearRows,
}) {
  return await axios.post(
    `/api/requests/save`,
    {
      sheet: table,
      url,
      method,
      params,
      headers,
      basicAuth,
      body,
      bodyType,
      selectedMappings,
      name,
      id,
      clearRows,
      ...scheduleConfig,
    },
    {
      headers: {
        Accept: 'application/json',
        [`x-auth-token`]: supabase.auth.session()?.access_token,
      },
    }
  );
}

const SupaForm = ({ request, setRequest }) => {
  const { data, loading } = useQuery('/api/smartsheet/sheets');

  const [msg, setMsg] = useState(``);
  // const [tables, setTables] = useState(data?.data?.data);

  const [table, setTable] = useState(request?.sheet || '');
  const [runData, setRunData] = useState({});
  const [selectedMappings, setSelectedMappings] = useState(
    request?.selectedMappings || []
  );
  const [runLoading, setRunLoading] = useState(false);
  const [fullResponse, setFullResponse] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [urlError, setUrlError] = useState(undefined);
  const [supabaseCreds, setSupabaseCreds] = useState({
    supaUrl: ``,
    supaServiceRoleKey: ``,
  });
  const [url, setUrl] = useState(request?.url || '');
  const [name, setName] = useState(request?.name || 'Untitled Request');
  const [method, setMethod] = useState(request?.method || 'GET');
  const [params, setParams] = useState(request?.params || []);
  const [headers, setHeaders] = useState(request?.headers || []);
  const [scheduleConfig, setScheduleConfig] = useState({
    scheduleEnabled: request?.scheduleEnabled || false,
    scheduleFrequency: request?.scheduleFrequency || '',
    scheduleDays: request?.scheduleDays || [],
  });
  const [basicAuth, setBasicAuth] = useState(
    request?.basicAuth || {
      username: undefined,
      password: undefined,
      enabled: false,
    }
  );
  const [clearRows, setClearRows] = useState(request?.clearRows ?? false);
  const [body, setBody] = useState(
    request?.body || {
      json: '',
      formData: [],
      xml: '',
    }
  );
  const [bodyType, setBodyType] = useState(request?.bodyType || 'json');

  const handleTableChange = (value) => {
    setTable(value);
  };

  const commonProps = {
    table,
    setTable: handleTableChange,
  };

  const customRequestProps = {
    method,
    setMethod,
    params,
    setParams,
    headers,
    setHeaders,
    body,
    setBody,
    bodyType,
    setBodyType,
    url,
    setUrl,
    basicAuth,
    setBasicAuth,
    urlError,
    setUrlError,
    fullResponse,
  };

  if (loading) {
    return (
      <div style={{ minHeight: '100vh', display: 'flex' }}>
        <div className="loader" />
      </div>
    );
  }

  return (
    <div
      style={{
        margin: 'auto',
        padding: '1rem',
        maxWidth: '1000px',
        width: '100%',
        minHeight: '100vh',
      }}
    >
      {data?.error === `Your Access Token has expired.` && (
        <Error style={{ marginBottom: '1rem' }}>
          Your Access Token has expired.{' '}
          <Link to="/authorize">Please re-authorize here</Link>
        </Error>
      )}
      <CustomRequestContainer>
        <h2>Name & Save</h2>
        <SubHeader>Save your request to easily reuse it later</SubHeader>
        <input
          style={{ width: '100%', marginBottom: '.5rem' }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        {msg && <Msg style={{ marginBottom: '.5rem' }}>{msg}</Msg>}
        <Button
          type="secondary"
          onClick={async () => {
            const { data } = await saveRequest({
              table,
              url,
              method,
              params,
              headers,
              basicAuth,
              body,
              bodyType,
              selectedMappings,
              name,
              id: request?.id,
              scheduleConfig,
              clearRows,
            });

            if (data?.success) {
              messageTimeout(setMsg, `Request Saved`);
            }

            if (!request?.id) {
              setRequest(data?.data);
            }
          }}
        >
          Save Request
        </Button>
      </CustomRequestContainer>
      <CustomRequest customRequestProps={customRequestProps} />
      <CustomRequestContainer style={{ marginTop: '1rem' }}>
        <h2>Select Sheet & Run Request</h2>
        <SubHeader>
          The table for which the data will be inserted into
        </SubHeader>
        <TableSelect
          tables={data?.data?.data}
          // tables={tables || data?.data?.data}
          commonProps={commonProps}
        />
        {error && <Error style={{ marginBottom: '.5rem' }}>{error}</Error>}
        <Button
          type="secondary"
          disabled={!table || runLoading}
          onClick={async () => {
            setRunLoading(true);
            const {
              data,
              success,
              fullResponse: response,
            } = await runQuery({
              type: 'idc right now',
              table,
              customRequestProps,
              setRunData,
              setFullResponse,
            });
            setRunLoading(false);
            if (success === false) {
              setFullResponse(response);
              setError(data);
              return;
            }

            if (error) setError(undefined);
            setFullResponse(response);
            setRunData(data);
            if (!request?.selectedMappings)
              setSelectedMappings(data?.mappedFields);
          }}
        >
          Run
        </Button>
      </CustomRequestContainer>

      {runData && selectedMappings && (
        <CompareFields
          runData={runData}
          customRequestProps={customRequestProps}
          table={table}
          supabaseCreds={supabaseCreds}
          selectedMappings={selectedMappings}
          setSelectedMappings={setSelectedMappings}
          setClearRows={setClearRows}
          clearRows={clearRows}
        />
      )}
      <Schedule
        config={scheduleConfig}
        setConfig={setScheduleConfig}
        requestId={request?.id}
      />
    </div>
  );
};

export default SupaForm;
