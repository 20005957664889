import React from 'react';
import styled from 'styled-components';
import Button from '../style/Button';

import { MultiInput } from './multi-input-form';

export const Textarea = styled.textarea`
  width: 100%;
  max-width: 100%;
  height: 100px;
  padding: 0.25rem;
  border-radius: 5px;

  &:focus {
    outline: none;
  }
`;

const RadioButton = styled.input`
  margin-right: 0.25rem;
`;

const RadioLabel = styled.span`
  margin-right: 1rem;
`;

const CustomBody = ({ body, setBody, bodyType, setBodyType }) => {
  // JSON, form-data, xml. Later -> x-www-form-urlencoded, GraphQL

  function handleChange(value, id, key) {
    setBody({
      ...body,
      formData: body?.formData?.map((p, i) => {
        if (i === id) {
          return {
            ...p,
            [`${key}`]: value,
          };
        }

        return p;
      }),
    });
  }

  return (
    <div>
      <div style={{ marginBottom: '.5rem' }}>
        <RadioButton
          type="radio"
          name="bodyType"
          checked={bodyType === 'json' && 'checked'}
          onChange={(e) => {
            setBodyType(e.target.value);
          }}
          value="json"
        />{' '}
        <RadioLabel>json</RadioLabel>
        <RadioButton
          type="radio"
          name="bodyType"
          checked={bodyType === 'form-data' && 'checked'}
          onChange={(e) => {
            setBodyType(e.target.value);
          }}
          value="form-data"
        />{' '}
        <RadioLabel>form-data</RadioLabel>
        <RadioButton
          type="radio"
          name="bodyType"
          checked={bodyType === 'xml' && 'checked'}
          onChange={(e) => {
            setBodyType(e.target.value);
          }}
          value="xml"
        />{' '}
        <RadioLabel>xml</RadioLabel>
      </div>

      {bodyType === 'json' && (
        <Textarea
          value={body.json}
          placeholder="json body..."
          name="json"
          onChange={(e) =>
            setBody({ ...body, [e.target.name]: e.target.value })
          }
        />
      )}

      {bodyType === 'form-data' && (
        <div>
          {body?.formData?.length > 0 && (
            <div>
              {body?.formData.map((p, i) => (
                <div
                  style={{
                    display: 'flex',
                    marginBottom: '.5rem',
                  }}
                  key={i}
                >
                  <MultiInput
                    placeholder="key"
                    value={p.key}
                    onChange={(e) => handleChange(e.target.value, i, 'key')}
                  />
                  <MultiInput
                    placeholder="value"
                    value={p.value}
                    onChange={(e) => handleChange(e.target.value, i, 'value')}
                  />
                  <Button
                    type="secondary"
                    style={{ padding: '0.3rem 0.75rem', flexGrow: '1' }}
                    onClick={() =>
                      setBody({
                        ...body,
                        formData: body?.formData.filter(
                          (p, index) => i !== index
                        ),
                      })
                    }
                  >
                    -
                  </Button>
                </div>
              ))}
            </div>
          )}
          <Button
            type="secondary"
            onClick={() =>
              setBody({
                ...body,
                formData: [...body.formData, { key: '', value: '' }],
              })
            }
          >
            Add +
          </Button>
        </div>
      )}

      {bodyType === 'xml' && (
        <Textarea
          value={body.xml}
          placeholder="xml body..."
          name="xml"
          onChange={(e) =>
            setBody({ ...body, [e.target.name]: e.target.value })
          }
        />
      )}
    </div>
  );
};

export default CustomBody;
