import React from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import { supabase } from "../../utils/supabase";
import Button from "./Button";

const Nav = styled.div`
  //   background-color: #ededed;
  width: 100%;
`;

const NavContainer = styled.div`
  margin: auto;
  padding: 1rem;
  max-width: 1000px;
  display: flex;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const LinkButton = styled(Link)`
  // text-decoration: underline;
  font-size: var(--font-sm);
  margin: auto 1.5rem auto 0;
  text-align: center;
  color: black;

  &: hover {
    cursor: pointer;
  }

  @media (max-width: 500px) {
    margin-right: 0;
  }
`;

const NavTitle = styled.h1`
  // display: inline-block;
  &: hover {
    cursor: pointer;
  }

  @media (max-width: 500px) {
    text-align: center;
  }
`;

const SubNav = styled.div`
  margin: 0 0 0 auto;
  display: flex;
  align-items: center;
  align-content: center;

  @media (max-width: 500px) {
    margin: auto;
    flex-direction: column;

    > * {
      margin-top: 0.25rem;
    }
  }
`;

const LogoutButton = styled(Button)`
  // margin-left: 1.5rem !important;

  @media (max-width: 500px) {
    margin-left: 0;
  }
`;

export const NavButton = styled(Link)`
  // margin-left: 1.5rem;

  padding: 0.4rem 0.75rem;
  font-weight: 700;
  // border: 1px solid var(--main-bg-color);
  border-radius: 5px;
  font-size: var(--font-md);
  // color: var(--black);

  color: white;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);

  &:hover {
    background-color: white;
    color: var(--main-color);
    border: 1px solid var(--main-color);
  }
`;

const Layout = ({ children }) => {
  const navigate = useNavigate();

  return (
    <div>
      <Nav>
        <NavContainer>
          <NavTitle>
            <Link to="/">API Retriever</Link>
          </NavTitle>

          <SubNav>
            <LinkButton to="/home">Home</LinkButton>
            <LinkButton to="/docs">Docs</LinkButton>
            <LinkButton to="/blog/how-to-import-data-with-smartsheet">
              Blog
            </LinkButton>
            {supabase.auth.session()?.access_token ? (
              <LogoutButton
                type="secondary"
                onClick={async () => {
                  const { error } = await supabase.auth.signOut();
                  if (error) {
                  }
                  navigate("/login");
                }}
              >
                Logout
              </LogoutButton>
            ) : (
              <>
                <LinkButton to="/login">Login</LinkButton>
                <NavButton to="/sign-up">Sign Up</NavButton>
              </>
            )}
          </SubNav>
        </NavContainer>
      </Nav>
      <div style={{ minHeight: "99vh" }}>{children}</div>
      <div style={{ display: "flex" }}>
        <div style={{ fontSize: "16px", margin: "1rem auto" }}>
          <a
            href="https://buy.stripe.com/7sI8yTdRbeSN45aaEE"
            style={{ marginRight: "1rem", color: "black" }}
            target="_blank"
          >
            Support Me
          </a>
          <a
            href="mailto:apiretriever@gmail.com"
            style={{ marginRight: "1rem", color: "black" }}
          >
            Contact Me
          </a>
          <Link to="/privacy" style={{ marginRight: "1rem", color: "black" }}>
            Privacy Policy
          </Link>
          <Link to="/terms" style={{ color: "black" }}>
            Terms of Use
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Layout;
