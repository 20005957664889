import React from 'react';

import MultiInputForm from './multi-input-form';

const CustomParams = ({ params, setParams }) => {
  return (
    <MultiInputForm items={params} setItems={setParams} type="parameter" />
  );
};

export default CustomParams;
