import React, { useEffect, useState } from 'react';
import SupaForm from './supa-form';
import Layout from './style/layout';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { supabase } from '../utils/supabase';

async function getRequest(id, setRequest, setLoading) {
  setLoading(true);
  const { data } = await axios.get(`/api/requests/${id}`, {
    headers: {
      Accept: 'application/json',
      [`x-auth-token`]: supabase.auth.session()?.access_token,
    },
  });
  setLoading(false);

  setRequest(data?.data);
}

const Home = () => {
  const { id } = useParams();
  const [request, setRequest] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      getRequest(id, setRequest, setLoading);
    }
  }, [id]);

  return (
    <Layout style={{ display: 'flex' }}>
      {loading ? (
        <div style={{ minHeight: '100vh', display: 'flex' }}>
          <div className="loader" />
        </div>
      ) : (
        <SupaForm request={request} setRequest={setRequest} />
      )}
    </Layout>
  );
};

export default Home;
