import React, { useState } from 'react';
import styled from 'styled-components';
import { Error } from '../style/error';
import { FakeLink } from '../style';
import { SubHeader } from '../supa-form';

import CustomParams from './custom-params';
import CustomHeaders from './custom-headers';
import CustomBody, { Textarea } from './custom-body';
import { MultiInput } from './multi-input-form';

export const CustomRequestContainer = styled.div`
  padding: 0.5rem;
  border: 1px solid #ededed;
  border-radius: 5px;
`;

const CustomSectionContainer = styled.div`
  margin-top: 0.5rem;
`;

const SectionTitle = styled.h3`
  margin-bottom: 0.5rem;
`;

const CustomRequest = ({ customRequestProps }) => {
  const {
    method,
    setMethod,
    params,
    setParams,
    headers,
    setHeaders,
    body,
    setBody,
    bodyType,
    setBodyType,
    url,
    setUrl,
    basicAuth,
    setBasicAuth,
    urlError,
    fullResponse,
  } = customRequestProps;

  const [showResponse, setShowResponse] = useState(false);

  return (
    <CustomRequestContainer style={{ marginTop: '1rem' }}>
      <h2>Create Custom Request</h2>
      <SubHeader>
        Create a request to any endpoint to fetch data to be inserted into a
        sheet
      </SubHeader>
      {urlError && <Error style={{ marginTop: '.5rem' }}>{urlError}</Error>}
      <CustomSectionContainer style={{ display: 'flex' }}>
        <select
          style={{ flexGrow: 1, marginRight: '.5%' }}
          value={method}
          onChange={(e) => setMethod(e.target.value)}
        >
          <option value="GET">GET</option>
          <option value="POST">POST</option>
          <option value="PUT">PUT</option>
          <option value="PATCH">PATCH</option>
          <option value="DELETE">DELETE</option>
        </select>

        <input
          type="text"
          placeholder="URL"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          style={{ flexGrow: '20' }}
        />
      </CustomSectionContainer>

      <CustomSectionContainer>
        <SectionTitle>Params</SectionTitle>
        <CustomParams params={params} setParams={setParams} />
      </CustomSectionContainer>
      <CustomSectionContainer>
        <SectionTitle>Headers</SectionTitle>
        <CustomHeaders headers={headers} setHeaders={setHeaders} />
      </CustomSectionContainer>

      <div
        style={{ marginTop: '.5rem', display: 'flex', alignItems: 'center' }}
      >
        <input
          type="checkbox"
          checked={basicAuth.enabled}
          onChange={(e) =>
            setBasicAuth({ ...basicAuth, enabled: e.target.checked })
          }
        />
        <span
          style={{ marginLeft: '.25rem', fontSize: '12px' }}
          onClick={() =>
            setBasicAuth({ ...basicAuth, enabled: !basicAuth.enabled })
          }
        >
          use basic auth
        </span>
      </div>
      {basicAuth?.enabled && (
        <div
          style={{
            display: 'flex',
            margin: '.5rem 0',
          }}
        >
          <MultiInput
            placeholder="username"
            name="username"
            value={basicAuth.username}
            onChange={(e) =>
              setBasicAuth({ ...basicAuth, [e.target.name]: e.target.value })
            }
          />
          <MultiInput
            placeholder="password"
            name="password"
            value={basicAuth.password}
            type="password"
            onChange={(e) =>
              setBasicAuth({ ...basicAuth, [e.target.name]: e.target.value })
            }
          />
        </div>
      )}

      <CustomSectionContainer style={{ width: '100%', marginBottom: '.5rem' }}>
        <SectionTitle>Body</SectionTitle>
        <CustomBody
          body={body}
          setBody={setBody}
          bodyType={bodyType}
          setBodyType={setBodyType}
        />
      </CustomSectionContainer>

      {fullResponse &&
        (!showResponse ? (
          <FakeLink onClick={() => setShowResponse(true)}>
            view your request response
          </FakeLink>
        ) : (
          <FakeLink onClick={() => setShowResponse(false)}>
            hide response
          </FakeLink>
        ))}
      {showResponse && (
        <Textarea
          style={{ marginTop: '.5rem' }}
          disabled
          value={JSON.stringify(fullResponse, undefined, 2)}
        />
      )}
    </CustomRequestContainer>
  );
};

export default CustomRequest;
