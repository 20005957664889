import React from 'react';
import styled from 'styled-components';

const Secondary = styled.button`
  color: var(--black);
  background-color: var(--white);
  border: 1px solid var(--black);
  //   font-size: 14px;
`;

const Primary = styled.button`
  color: white;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);

  &:hover {
    background-color: white;
    color: var(--main-color);
  }
`;

const Button = ({ children, onClick, type, style, disabled }) => {
  if (type === `secondary`) {
    return (
      <Secondary style={style} disabled={disabled} onClick={(e) => onClick(e)}>
        {children}
      </Secondary>
    );
  }

  return (
    <Primary style={style} onClick={(e) => onClick(e)} disabled={disabled}>
      {children}
    </Primary>
  );
};

export default Button;
