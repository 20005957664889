import React from 'react';
import Layout from './style/layout';
import { SubHeader } from './supa-form';
import { LinkBtn } from './style';

const SmartsheetAuth = () => {
  return (
    <Layout>
      <div style={{ minWidth: '300px', maxWidth: '500px', margin: 'auto' }}>
        <h2>Authorize Access To Smartsheet</h2>
        <SubHeader>
          Authorize us to read, write, and add columns to your Smartsheet
          account
        </SubHeader>
        <div style={{ marginTop: '1rem' }}>
          <LinkBtn
            href={`https://app.smartsheet.com/b/authorize?response_type=code&client_id=${process.env.REACT_APP_SMARTSHEET_APP_CLIENT_ID}&scope=READ_SHEETS%20WRITE_SHEETS%20ADMIN_SHEETS&state=random`}
          >
            Authorize
          </LinkBtn>
        </div>
      </div>
    </Layout>
  );
};

export default SmartsheetAuth;
