import React, { useState } from 'react';
import { useQuery } from '../hooks/useQuery';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { supabase } from '../utils/supabase';
import Modal from './modal';
import Button from './style/Button';
import { SubHeader } from './supa-form';

const RequestListContainer = styled.div`
  margin-top: 1rem;
  max-height: 900px;
  overflow: scroll;
`;

const RequestListItem = styled.div`
  padding: 0.5rem;
  border: 1px solid var(--grey);
  border-radius: 5px;
  margin-bottom: 0.5rem;
  font-size: 12px;
  display: flex;
  align-items: center;

  &:hover {
    cursor: pointer;
    background-color: var(--light-grey);
    // border: 1px solid var(--main-color);
  }
`;

const RequestListItemText = styled.p`
  font-size: 16px;
  font-weight: 700;
  margin: auto 0 0.25rem 0;
`;

const RequestListItemDetails = styled.div`
  overflow: hidden;
  margin-right: 1rem;
`;

const EditButton = styled.div`
  font-size: 16px;
  margin-left: 1rem;
  background-color: var(--grey);
  padding: 0.25rem 0.5rem;
  border-radius: 2px;

  &:hover {
    cursor: pointer;
  }
`;

const deleteRequest = async (id) => {
  const token = supabase.auth.session()?.access_token;

  const { data } = await axios.delete(`/api/requests/${id}`, {
    headers: {
      [`x-auth-token`]: token,
    },
  });
  return data;
};

const RequestList = ({ isAuthed }) => {
  const { data, getData } = useQuery(`/api/requests/list`);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [deleteRequestModal, setDeleteRequestModal] = useState({
    id: ``,
    visible: false,
  });

  const requests = data?.data;

  return (
    <div>
      <div style={{ marginBottom: '.5rem', display: 'flex' }}>
        <h2 style={{ margin: 'auto 0' }}>Requests</h2>
        <div
          style={{
            margin: '0 0 0 auto',
            display: 'flex',
            flexDirection: 'column',
            fontWeight: '600',
          }}
        >
          <Button
            style={{ fontSize: '20px' }}
            onClick={() => navigate(`/requests`)}
            disabled={!isAuthed}
          >
            Create
          </Button>
        </div>
      </div>
      <RequestListContainer>
        {requests?.length === 0 ? (
          <SubHeader style={{ textAlign: 'center' }}>
            Create your first request!
          </SubHeader>
        ) : (
          requests?.map((f) => (
            <RequestListItem
              key={f.id}
              onClick={() => navigate(`/requests/${f.id}`)}
            >
              <RequestListItemDetails>
                <RequestListItemText>{f?.name}</RequestListItemText>
                {/* <div style={{ display: 'flex', marginBottom: '.25rem' }}>
                  <RequestListItemText>{f?.name}</RequestListItemText>
                  <span style={{ margin: 'auto 0' }}> - {f.method}</span>
                </div> */}
                {f.url && (
                  <>
                    <p style={{ whiteSpace: 'nowrap' }}>
                      {f.method} - {f.url}
                    </p>
                  </>
                )}
              </RequestListItemDetails>
              <div style={{ margin: '0 0 0 auto', display: 'flex' }}>
                <Button
                  style={{ fontSize: '12px' }}
                  type="secondary"
                  onClick={async (e) => {
                    e.stopPropagation();
                    // show confirm popup
                    setDeleteRequestModal({
                      visible: true,
                      id: f.id,
                    });
                  }}
                >
                  Delete
                </Button>
              </div>
            </RequestListItem>
          ))
        )}
      </RequestListContainer>

      <Modal
        title="Delete Request"
        loading={loading}
        onCancel={() =>
          setDeleteRequestModal({
            visible: false,
            id: ``,
          })
        }
        onOk={async () => {
          setLoading(true);
          const res = await deleteRequest(deleteRequestModal.id);
          setLoading(false);

          if (res?.success) {
            setDeleteRequestModal({
              visible: false,
              id: ``,
            });

            getData();
          }
        }}
        visible={deleteRequestModal.visible}
      >
        <p>Please confirm you would like to delete this Request</p>
      </Modal>
    </div>
  );
};

export default RequestList;
