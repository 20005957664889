import React from 'react';

const TableSelect = ({ tables, commonProps }) => {
  const { table, setTable } = commonProps;

  return (
    <div>
      <select
        value={table}
        onChange={(e) => setTable(e.target.value)}
        style={{ width: '100%', margin: '0 0 .5rem 0' }}
      >
        <option value={''}>Select Table...</option>
        {tables?.map((t) => (
          <option key={t.id} value={t.id}>
            {t.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TableSelect;
