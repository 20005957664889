import React, { useState } from 'react';
import styled from 'styled-components';
import { supabase } from '../../utils/supabase';
import { Msg } from '../style';
import Layout from '../style/layout';
import { Error } from '../style/error';
import Button from '../style/Button';

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  border: 1px solid #ededed;
  border-radius: 5px;
  padding: 3rem 1rem;
  text-align: center;
  margin: 5rem auto auto auto;

  > * {
    margin-top: 1rem !important;
  }
`;

const SignUp = () => {
  const [creds, setCreds] = useState({
    email: '',
    password: '',
  });
  const [msg, setMsg] = useState('');
  const [error, setError] = useState(``);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) =>
    setCreds({ ...creds, [e.target.name]: e.target.value });

  const handleSubmit = async (type) => {
    setLoading(true);
    let signUpError;
    if (type === `email`) {
      const { error: newSignUpError } = await supabase.auth.signUp(
        {
          email: creds.email,
          password: creds.password,
        },
        { redirectTo: `${process.env.REACT_APP_BASE_URL}/callback` }
      );

      signUpError = newSignUpError;
    } else {
      const { error: newSignUpError } = await supabase.auth.signIn(
        {
          provider: 'google',
        },
        { redirectTo: `${process.env.REACT_APP_BASE_URL}/callback` }
      );

      signUpError = newSignUpError;
    }

    setLoading(false);

    if (signUpError?.message) {
      setError(signUpError?.message);
      return;
    }

    // await axios.post(`/api/supabase/users/create`, {
    //   id: user.id,
    //   email: user?.email,
    // });

    setCreds({
      email: '',
      password: '',
    });
    setMsg(`Please confirm your email`);
  };

  return (
    <Layout>
      <div style={{ display: 'flex', minHeight: '100vh' }}>
        <Form>
          <h2>Sign Up</h2>
          {error && <Error>{error}</Error>}
          {msg && <Msg>{msg}</Msg>}
          <input
            value={creds.email}
            placeholder="Email"
            name="email"
            onChange={(e) => handleChange(e)}
          />
          <input
            value={creds.password}
            type="password"
            placeholder="Password"
            name="password"
            onChange={(e) => handleChange(e)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleSubmit(`email`);
              }
            }}
          />
          <Button onClick={() => handleSubmit(`email`)} disabled={loading}>
            Sign Up
          </Button>
          {/* <button onClick={() => handleSubmit(`google`)} disabled={loading}>
            Sign Up With Google
          </button> */}
        </Form>
      </div>
    </Layout>
  );
};

export default SignUp;
