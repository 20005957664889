import React, { useState, useEffect } from 'react';
import RequestList from './request-list';
import Layout from './style/layout';
import { supabase } from '../utils/supabase';
import axios from 'axios';
import { SubHeader } from './supa-form';
import { LinkBtn } from './style';

const getUser = async (setUser, setDashboardLoading) => {
  // setDashboardLoading(true);
  const { data } = await axios.get(`/api/supabase/user`, {
    headers: { [`x-auth-token`]: supabase.auth.session()?.access_token },
  });
  setDashboardLoading(false);

  setUser(data?.data);
};

const Dashboard = () => {
  const [user, setUser] = useState({});
  const [dashboardLoading, setDashboardLoading] = useState(true);

  useEffect(() => {
    getUser(setUser, setDashboardLoading);
  }, []);

  return (
    <Layout>
      <div style={{ padding: '1rem' }}>
        {dashboardLoading ? (
          <div className="loader" />
        ) : (
          <div>
            {user?.isAuthed ? (
              <div
                style={{ minWidth: '300px', maxWidth: '750px', margin: 'auto' }}
              >
                <RequestList
                  isAuthed={user?.isAuthed}
                  setDashboardLoading={setDashboardLoading}
                />
              </div>
            ) : (
              <div
                style={{ minWidth: '300px', maxWidth: '750px', margin: 'auto' }}
              >
                <h2>Authorize Access To Smartsheet</h2>
                <SubHeader>
                  Authorize us to read, write, and add columns to your
                  Smartsheet account
                </SubHeader>
                <div style={{ marginTop: '1rem' }}>
                  <LinkBtn
                    href={`https://app.smartsheet.com/b/authorize?response_type=code&client_id=${process.env.REACT_APP_SMARTSHEET_APP_CLIENT_ID}&scope=READ_SHEETS%20WRITE_SHEETS%20ADMIN_SHEETS&state=random`}
                  >
                    Authorize
                  </LinkBtn>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Dashboard;
