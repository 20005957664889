export function getCustomRequestConfig(customRequestProps) {
  const { method, params, headers, url, body, bodyType, basicAuth } =
    customRequestProps;
  const paramsObj = {};
  const headersObj = {};
  const formData = {};

  params?.forEach((p) => (paramsObj[`${p.parameter}`] = p.value));
  headers?.forEach((p) => (headersObj[`${p.header}`] = p.value));

  if (method !== 'GET') {
    if (bodyType === 'json') {
      headersObj[`Content-Type`] = `application/json`;
    } else if (bodyType === 'xml') {
      headersObj[`Content-Type`] = `application/xml`;
    } else if (bodyType === 'form-data') {
      headersObj[`Content-Type`] = `multipart/form-data`;
      body?.formData?.forEach((p) => (formData[`${p.key}`] = p.value));
    }
  }

  let bodyData = bodyType === `form-data` ? formData : body[`${bodyType}`];
  if (method === 'GET') {
    bodyData = undefined;
  }

  const config = {
    url,
    method,
    params: paramsObj,
    headers: headersObj,
    data: bodyData,
    auth: basicAuth?.enabled
      ? {
          username: basicAuth?.username,
          password: basicAuth?.password,
        }
      : undefined,
  };

  return config;
}
