import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  useLocation,
  Navigate,
  useSearchParams,
  useNavigate,
} from 'react-router-dom';
import PrivateRoute from './components/private-route';
import Home from './components/home';
import Fetcher from './components/fetcher';
import SignUp from './components/auth/sign-up';
import Login from './components/auth/login';
import Docs from './components/docs';
import HowToImportData from './components/how-to-import-data';
// import NotFound from './components/not-found';
import PrivacyPolicy from './components/privacy-policy';
import TermsOfUse from './components/terms-of-use';
import Dashboard from './components/dashboard';
import SmartsheetAuth from './components/smartsheet-auth';
import axios from 'axios';
import { supabase } from './utils/supabase';

const FindRoute = () => {
  const location = useLocation();

  const metaData = location.hash
    ?.split('&')
    ?.find((i) => i.includes('access_token'));
  if (metaData) {
    const accessToken = metaData?.split('=')[1];
    if (accessToken) {
      supabase.auth.setAuth(accessToken);

      return <Navigate to="/home" />;
    }
  }

  return <Home />;
};

async function handleAuth(code, state, navigate) {
  const { data } = await axios.post(
    `/api/smartsheet/auth`,
    {
      code,
      state,
    },
    {
      headers: {
        Accept: 'application/json',
        [`x-auth-token`]: supabase.auth.session()?.access_token,
      },
    }
  );

  return navigate('/home');
}

const FindSmartsheetAuth = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get(`code`);
  const state = searchParams.get(`state`);
  const error = searchParams.get(`error`);

  useEffect(() => {
    if (code && !error) {
      handleAuth(code, state, navigate);
    }
  }, []);

  if (error) {
    return <Dashboard />;
  }
};

const Router = () => {
  return (
    <Routes>
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/docs" element={<Docs />} />
      <Route path="/login" element={<Login />} />
      <Route
        path="/blog/how-to-import-data-with-smartsheet"
        element={<HowToImportData />}
      />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/terms" element={<TermsOfUse />} />
      <Route path="/smartsheet/callback/*" element={<FindSmartsheetAuth />} />
      <Route path="/callback/*" element={<FindRoute />} />
      {/* <Route
        path="/setup"
        element={
          <PrivateRoute>
            <SupaAuth />
          </PrivateRoute>
        }
      /> */}
      <Route
        path="/authorize"
        element={
          <PrivateRoute>
            <SmartsheetAuth />
          </PrivateRoute>
        }
      />
      <Route
        path="/home"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
      <Route
        path="/requests/:id"
        element={
          <PrivateRoute>
            <Fetcher />
          </PrivateRoute>
        }
      />
      <Route
        path="/requests"
        element={
          <PrivateRoute>
            <Fetcher />
          </PrivateRoute>
        }
      />
      <Route path="/" element={<Home />} />
      {/* <Route path="*" element={<NotFound />} /> */}
    </Routes>
  );
};

export default Router;
