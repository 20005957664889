import styled from 'styled-components';

export const FakeLink = styled.p`
  color: #b6b8b6;
  text-decoration: underline;
  font-size: 13px;

  &: hover {
    cursor: pointer;
  }
`;

export const Msg = styled.p`
  padding: 0.25rem;
  border: 1px solid green;
  border-radius: 5px;
  background-color: #e5fae3;
  font-size: 13px;
`;

export const LinkBtn = styled.a`
  padding: 0.4rem 0.75rem;
  font-weight: 700;
  // border: 1px solid var(--main-bg-color);
  border-radius: 5px;
  font-size: var(--font-md);
  // color: var(--black);

  color: white;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);

  &:hover {
    background-color: white;
    color: var(--main-color);
    border: 1px solid var(--main-color);
  }
`;
