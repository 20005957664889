import React from 'react';
import Button from '../style/Button';
import { Note } from '../docs';

const dayButtonStyle = {
  marginRight: '.25rem',
};

const On = ({ setConfig, config }) => {
  function onClick(value) {
    if (config.scheduleFrequency === 'weekly') {
      return setConfig({ ...config, scheduleDays: [value] });
    } else if (config.scheduleDays.includes(value)) {
      return setConfig({
        ...config,
        scheduleDays: config.scheduleDays.filter((day) => day !== value),
      });
    } else {
      return setConfig({
        ...config,
        scheduleDays: [...config.scheduleDays, value],
      });
    }
  }

  if (!config.scheduleFrequency) return null;

  return (
    <div>
      <h3 style={{ marginTop: '.5rem' }}>On</h3>
      <div style={{ marginTop: '.5rem' }}>
        <Button
          style={dayButtonStyle}
          onClick={() => onClick('1')}
          type={config.scheduleDays?.includes('1') ? '' : 'secondary'}
        >
          Mon
        </Button>
        <Button
          style={dayButtonStyle}
          onClick={() => onClick('2')}
          type={config.scheduleDays?.includes('2') ? '' : 'secondary'}
        >
          Tues
        </Button>
        <Button
          style={dayButtonStyle}
          onClick={() => onClick('3')}
          type={config.scheduleDays?.includes('3') ? '' : 'secondary'}
        >
          Wed
        </Button>
        <Button
          style={dayButtonStyle}
          onClick={() => onClick('4')}
          type={config.scheduleDays?.includes('4') ? '' : 'secondary'}
        >
          Thur
        </Button>
        <Button
          style={dayButtonStyle}
          onClick={() => onClick('5')}
          type={config.scheduleDays?.includes('5') ? '' : 'secondary'}
        >
          Fri
        </Button>
        <Button
          style={dayButtonStyle}
          onClick={() => onClick('6')}
          type={config.scheduleDays?.includes('6') ? '' : 'secondary'}
        >
          Sat
        </Button>
        <Button
          style={dayButtonStyle}
          onClick={() => onClick('0')}
          type={config.scheduleDays?.includes('0') ? '' : 'secondary'}
        >
          Sun
        </Button>
        <Note style={{ marginTop: '.25rem' }}>*Will run at 00:00 UTC</Note>
      </div>
    </div>
  );
};

export default On;
