import React from 'react';

const TableFieldsSelect = ({
  fields,
  selectedMappings,
  setSelectedMappings,
  fieldName,
}) => {
  return (
    <div>
      <select
        style={{ width: '100%' }}
        value={
          selectedMappings?.find(
            (sm) => sm.fieldName?.toLowerCase() === fieldName?.toLowerCase()
          )?.smartsheetFieldName || ''
        }
        onChange={(e) => {
          setSelectedMappings(
            selectedMappings?.map((sm) => {
              const value = e.target.value;
              let item = sm;

              if (value === sm?.smartsheetFieldName) {
                item = { ...item, smartsheetFieldName: undefined };
              }

              if (sm.fieldName === fieldName) {
                return {
                  ...item,
                  smartsheetFieldName: value,
                };
              }

              return item;
            })
          );
        }}
      >
        <option value={''}>Select Field...</option>
        {fields?.map((f) => (
          <option key={f.title} value={f.title}>
            {f.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TableFieldsSelect;
